import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PrivateParking = () => {
    return (
        <Layout>
                        <Seo
                title="Stationnement chez les particuliers - BIKESAFE"
                description="Les ranges vélos que nous proposons pour les particuliers protègent votre vélo, vos murs et vos véhicules. Que vous recherchiez un système de rangement pour vélos pour votre maison, votre garage ou votre jardin, nous avons la solution !"
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Stationnement chez les particuliers
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h5>Rangez vos vélos de manière efficace et optimisez l'espace non utilisé !</h5>
                        <p className="flow-text">
                            Les ranges vélos que nous proposons pour les particuliers protègent votre vélo, vos murs et vos véhicules. Que vous recherchiez un système de rangement pour vélos pour votre maison, votre garage ou votre jardin, nous avons la solution ! Grâce à l'un de nos supports/coffres pour vélo, optimisez l'usage de votre espace au sol ou au mur !
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/sheffield.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">« Nous avons été très satisfaits de l'amabilité et de l'efficacité de l'équipe de Bike Safe. Ils ont installé un support en arceau dans notre jardin quelques jours après les avoir contactés. Le support est super et très pratique pour ranger nos vélos sans encombrer l'entrée. »
                                <strong> Emlyn Lamburn, propriétaire d'un logement, Londres</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PrivateParking;